


















import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import TableBase from '@/components/atoms/TableBase1110.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import TabBase from '@/components/atoms/v3/TabBase.vue'
import QuestionImage from '@/components/modules/drillsv3/molecules/QuestionImage.vue'
import Timelines from '@/mixins/v3/Timelines'

interface BookmarkReponse {
    id: number;
    questionCode: string;
    questionImage: {
        d: string[];
        m: string[];
        j: string[];
    },
    curriculumSUnitName: string;
    curriculumSUnitId: number
}

@Component({
    components: {
        TableBase,
        SidebarSwitcher,
        TabBase,
        QuestionImage
    }
})

export default class Bookmark extends Mixins(Timelines) {
    @Ref() questionImage!: QuestionImage
    private pagination: { page: number; per_page: number, totalPage: number } = { page: 1, per_page: 10, totalPage: 1 }
    private currentRow: any = {
        questionImage: {
            d: [],
            j: [],
            m: [],
        },
        subjectCode: this.$route.params.subjectCode,
    }
    private tabs: { link: string; name: string }[] = [
        {
            link: `/student/v2/curriculum-s-units/${this.$route.params.classMode}/${this.$route.params.subjectCode}`,
            name: '間違い直し',
        },
        {
            link: `/student/v3/bookmark/${this.$route.params.classMode}/${this.$route.params.subjectCode}`,
            name: 'ブックマーク',
        }
    ]

    private dataTable: BookmarkReponse[] = []

    private tableFields = [
        { key: 'curriculumSUnitName', label: '項目' },
        { key: 'questionCodeLink', label: '問題表示' },
        { key: 'detail_bookmark', label: '問題' },
    ]

    private handleShowQuestion(_number: number, index: number) {
        const data = this.dataTable[index] as BookmarkReponse
        this.currentRow = { ...this.currentRow, ...data }
        this.questionImage.showImage()
    }

    private handleFormatDataBookmark(data: BookmarkReponse[]) {
        return data.map((item: BookmarkReponse) => ({
            ...item,
            questionCodeLink: {
                name: item?.questionCode,
                onclick: this.handleShowQuestion,
            },
            detail_bookmark: [{
                name: '確認',
                onclick: () => this.$router.push(`/student/v3/bookmark/detail/${item.curriculumSUnitId}/${item.questionCode}`)
            }],
        }))
    }

    private async handleGetListBookmark(request: { page: number, per_page: number }): Promise<{ bookmarks: BookmarkReponse[], page: number; perPage: number; totalPage: number }> {
        Vue.prototype.$loading.start()
        const result = await Vue.prototype.$http.httpWithToken.get(`/v3/history/question_bookmarks`, { params: { page: request.page, per_page: request.per_page, subject_code: this.$route.params.subjectCode } })
        Vue.prototype.$loading.complete()
        return { bookmarks: result?.data?.bookmarks || [], page: parseInt(result?.data?.page) || 1, perPage: parseInt(result?.data?.perPage) || 10, totalPage: result?.data?.totalPage || 1 }
    }

    private async handleLoadMore() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollTop + windowHeight >= documentHeight) {
            if (this.pagination.page < this.pagination.totalPage) {
                const response = await this.handleGetListBookmark({ page: this.pagination.page + 1, per_page: this.pagination.per_page });
                const newData = this.handleFormatDataBookmark(response?.bookmarks || [])
                this.dataTable = [...this.dataTable, ...newData]
                this.pagination = { page: response?.page, per_page: response?.perPage, totalPage: response?.totalPage }
            }
        }
    }

    private async mounted() {
        const response = await this.handleGetListBookmark(this.pagination);
        this.dataTable = this.handleFormatDataBookmark(response.bookmarks)
        this.pagination = { page: response.page, per_page: response.perPage, totalPage: response.totalPage }
        window.addEventListener('scroll', this.handleLoadMore);
    }

    private beforeDestroy() {
        window.removeEventListener('scroll', this.handleLoadMore);
    }
}
